import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import {
    FuseNavigationItem,
    FuseNavigationService,
    FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { UserService } from 'app/shared/services/user.service';
import { AuthUser } from 'app/core/models/auth_user.model';
import { SearchComponent } from 'app/layout/common/search/search.component';
import { UserComponent } from 'app/layout/common/user/user.component';
import { Subject, takeUntil } from 'rxjs';
import { environment } from 'environments/environment';

@Component({
    selector: 'classy-layout',
    templateUrl: './classy.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        FuseLoadingBarComponent,
        FuseVerticalNavigationComponent,
        UserComponent,
        NgIf,
        MatIconModule,
        MatButtonModule,
        SearchComponent,
        RouterOutlet,
    ],
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: Navigation;
    navigationItems: FuseNavigationItem[] = [
        {
            id: 'home',
            title: 'Home',
            type: 'basic',
            icon: 'mat_outline:home',
            link: '/home',
        },
        {
            id: 'order-timeline',
            title: 'Timeline Ordenes',
            type: 'basic',
            icon: 'mat_outline:timeline',
            link: '/orders/timeline',
        },
        {
            id: 'oven-timeline',
            title: 'Ocupacion Horno',
            type: 'basic',
            icon: 'mat_outline:timeline',
            link: '/oven/ocupation',
        },
        {
            id: 'orders',
            title: 'Ordenes',
            type: 'basic',
            icon: 'mat_solid:fastfood',
            link: '/orders',
        },
        {
            id: 'revisions',
            title: 'Revisiones',
            type: 'basic',
            icon: 'mat_solid:view_in_ar',
            link: '/revisions',
        },
        // {
        //     id: 'ovens',
        //     title: 'Hornos',
        //     type: 'basic',
        //     icon: 'mat_outline:local_fire_department',
        //     link: '/oven/ovens',
        // },
        {
            id: 'stock',
            title: 'Stock',
            type: 'collapsable',
            icon: 'feather:package',
            children: [
                {
                    id: 'stock_exhibited',
                    title: 'Por Producto',
                    type: 'basic',
                    icon: 'feather:box',
                    link: '/stock/agrupated',
                },
                {
                    id: 'stock_expired',
                    title: 'Por Vencimiento',
                    type: 'basic',
                    icon: 'feather:box',
                    link: '/stock/exhibited',
                },
                {
                    id: 'stock_domino',
                    title: 'Stock Domino',
                    type: 'basic',
                    icon: 'feather:box',
                    link: '/stock/domino',
                },
                {
                    id: 'movements',
                    title: 'Movimientos Stock Exhibido',
                    type: 'basic',
                    icon: 'feather:list',
                    link: '/movements/records',
                },
            ],
        },
        {
            id: 'movements',
            title: 'Movimientos Domino',
            type: 'basic',
            icon: 'mat_outline:swap_horiz',
            link: '/movements',
        },
        {
            id: 'administration',
            title: 'Administracion',
            type: 'collapsable',
            icon: 'feather:truck',
            children: [
                {
                    id: 'products',
                    title: 'Products',
                    type: 'basic',
                    icon: 'mat_outline:local_drink',
                    link: '/products',
                },
                {
                    id: 'planogram',
                    title: 'Planograma',
                    type: 'basic',
                    icon: 'mat_outline:border_all',
                    link: '/planogram',
                },
            ],
        },
    ];

    user: AuthUser;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _userService: UserService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    get isProd(): boolean {
        return environment.production;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to navigation data
        // this._navigationService.navigation$
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((navigation: Navigation) => {
        //         this.navigation = navigation;
        //     });

        // Subscribe to the user service
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: AuthUser) => {
                this.user = user;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
                name
            );

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    get subsidiaryName() {
        // const testSubsidiary = this.user.subsidiaries.find(
        //     (subsidiary) => subsidiary.code === '1230'
        // );
        // return (
        //     testSubsidiary.fantasyName ?? this.user.subsidiaries[0].fantasyName
        // );
        return this.user.subsidiaries[0].fantasyName;
    }
}
