import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpEvent,
    HttpHandlerFn,
    HttpResponse,
} from '@angular/common/http';
import { Observable, map } from 'rxjs';

export const baseTransformInterceptor = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
    // allow cors for all origins
    // req = req.clone({
    //     setHeaders: {
    //         'Access-Control-Allow-Origin': '*',
    //         'Access-Control-Allow-Headers': '*',
    //     },
    // });

    // Check if body is form data
    if (!(req.body instanceof FormData)) {
        req = req.clone({
            body: convertToSnakeCase(req.body),
        });
    }

    // Change all response body from snake_case to camelCase
    return next(req).pipe(
        map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                event = event.clone({
                    body: convertToCamelCase(event.body),
                });
            }
            return event;
        })
    );
};

// Recursive function to convert keys from snake_case to camelCase
const convertToCamelCase = (obj: any): any => {
    if (obj === null || obj === undefined || typeof obj !== 'object') {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map((item) => convertToCamelCase(item));
    }

    const camelCaseObj: { [key: string]: any } = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const newKey = key.replace(/_([a-z])/g, (match, letter) =>
                letter.toUpperCase()
            );
            camelCaseObj[newKey] = convertToCamelCase(obj[key]);
        }
    }

    return camelCaseObj;
};

function camelToSnakeCase(str: string): string {
    return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

function convertToSnakeCase(obj: any): any {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map(convertToSnakeCase);
    }

    return Object.entries(obj).reduce((acc, [key, value]) => {
        acc[camelToSnakeCase(key)] = convertToSnakeCase(value);
        return acc;
    }, {} as any);
}
