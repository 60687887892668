import { Injectable } from '@angular/core';
import { AuthUser } from 'app/core/models/auth_user.model';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService {
    private _userSubject: ReplaySubject<AuthUser> = new ReplaySubject<AuthUser>(
        1
    );
    private _userModel: AuthUser;

    constructor() {}

    /// Setea el usuario.
    set user(value: AuthUser) {
        this._userModel = value;
        this._userSubject.next(value);
    }

    /// Devuelve el usuario.
    get user$(): Observable<AuthUser> {
        return this._userSubject.asObservable();
    }
}
