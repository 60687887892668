import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpHeaders,
    HttpParams,
} from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';
import { throwError } from 'rxjs';
import { FilterModel } from '../../core/models/filters.models';
import { GeneralResponse } from 'app/shared/models/general_response.model';
import { ApiFilter } from 'app/types/apifilter.type';

@Injectable()
export class BaseService {
    public _api = environment.api;
    public _headers = new HttpHeaders();
    protected _filters: FilterModel[] = [];

    protected _sorts = '';

    constructor(public router: Router, filters: FilterModel[] = []) {
        this._headers
            .set('Accept', 'application/json')
            .append('Access-Control-Allow-Origin', '*')
            .append('Content-type', 'application/x-www-form-urlencoded')
            .append('X-Requested-With', 'XMLHttpRequest');

        this._filters = filters;
        this._sorts = '';
    }

    protected createFilters(filters: ApiFilter[] = []): HttpParams {
        if (filters.length === 0) return new HttpParams();
        let params = new HttpParams();
        filters.forEach((filter) => {
            if (filter.value) {
                params = params.append(filter.field, filter.value.toString());
            }
        });
        return params;
    }

    protected createPagination = (pagination: {
        take?: number;
        page?: number;
    }): HttpParams => {
        let params = new HttpParams();

        if (!pagination) return params;

        if (pagination.take)
            params = params.append('take', pagination.take.toString());

        if (pagination.page)
            params = params.append('page', pagination.page.toString());

        return params;
    };

    filterFormat(field: string, value: any): string {
        return `filter[${field}]=${value}&`;
    }

    public handleError(error: HttpErrorResponse | any): any {
        // debugger;
        const response = new GeneralResponse();
        response.data = [];
        response.error = true;

        if (error.error.data) {
            response.message = error.error.data.message;

            if (error.error.data.httpCode === 401) {
                this.router.navigate(['/login']);
            }

            if (error.error.data.errors) {
                const arrayErrors = Object.keys(error.error.data.errors).map(
                    (i) => error.error.data.errors[i]
                );
                for (const err of arrayErrors) {
                    response.data.push(err);
                }
            }
        }

        if (error.error) {
            response.message = error.error.message;

            if (error.error.httpCode === 401) {
                this.router.navigate(['/login']);
            }

            if (error.error.errors) {
                const arrayErrors = Object.keys(error.error.errors).map(
                    (i) => error.error.errors[i]
                );
                for (const err of arrayErrors) {
                    response.data.push(err);
                }
            }
        }

        /**
         * errors: {email: ["correo electrónico ya ha sido registrado."]} email: ["correo electrónico ya ha sido registrado."] 0: "correo electrónico ya ha sido registrado."e
         */

        if (response.message === '') {
            response.message =
                'Ocurrio un problema con la comunicación con el servidor. Verfique su conexion y vuelva a intentarlo.';
        }
        return throwError(response);
    }

    // ***** filters
    get filters(): FilterModel[] {
        return this._filters;
    }

    set filters(filters: FilterModel[]) {
        this._filters = filters;
    }

    cleanFilters(): void {
        for (const filter of this._filters) {
            if (filter.isArray) {
                filter.value = [];
            } else {
                filter.value = '';
            }
        }
    }

    get sorts(): string {
        return this._sorts;
    }

    set sorts(sorts) {
        this._sorts = sorts;
    }
    // -----
}
