export class GeneralResponse {
    constructor(
        public message: string = '',
        public error: boolean = false,
        public logout: boolean = false,
        public data: any = null,
        public links: any = null,
        public meta: any = null
    ) {}
}
