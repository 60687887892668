import { Injectable } from '@angular/core';

enum StorageKeys {
    AccessToken = 'AccessToken',
    TokenType = 'TokenType',
    User = 'User',
}

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    constructor() {}

    /// Setea el token del usuario.
    set accessToken(token: string) {
        localStorage.setItem(StorageKeys.AccessToken, token);
    }

    /// Borra el token del usuario.
    removeAccessToken() {
        localStorage.removeItem(StorageKeys.AccessToken);
    }

    /// Devuelve el token del usuario logueado.
    get accessToken(): string {
        return localStorage.getItem(StorageKeys.AccessToken) ?? '';
    }

    /// Setea el user.
    set user(user: string) {
        localStorage.setItem(StorageKeys.User, user);
    }

    /// Remueve el user.
    removeUser() {
        localStorage.removeItem(StorageKeys.User);
    }

    /// Devuelve el user.
    get user(): string {
        return localStorage.getItem(StorageKeys.User) ?? '';
    }

    // Limpia todos los datos del local storage.
    clearAll(): void {
        localStorage.clear();
    }
}
