import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';
import * as moment from 'moment-timezone';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent {
    /**
     * Constructor
     */
    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ) {
        const baseUrl = 'assets/icons/';
        const icons = [
            'shell_croissant',
            'shell_empanadas',
            'shell_pizza',
            'shell_burguer',
            'shell_cheese_bread',
            'shell_french_fries',
        ];

        icons.forEach((icon) => {
            this.matIconRegistry.addSvgIcon(
                icon, // Icon name
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    `${baseUrl}${icon}.svg`
                )
            );
        });

        moment.tz.setDefault('America/Argentina/Buenos_Aires'); // Set to Argentina timezone
    }
}
