import { Subsidiary } from 'app/types/subsidiary.type';
import { Serializable } from './serializable.model';

// Clase para representar el usuario logueado.
export class AuthUser extends Serializable {
    id: string;
    fullname: string;
    email: string;
    role: string;
    subsidiaries: Subsidiary[];

    /// Crea una nueva instancia del usuario.
    newModel(data: any): AuthUser {
        const dataParsed = new AuthUser();
        dataParsed.role = data.role;
        dataParsed.id = data.id;
        dataParsed.email = data.email;
        dataParsed.fullname = data.user;
        dataParsed.subsidiaries = data.subsidiaries;
        return dataParsed;
    }

    // Recibe un string en formato json que se recupero del local storage
    // y crea una instancia de un User.
    static newModelFromStorage(jsonString: any): AuthUser {
        const json = JSON.parse(jsonString);
        const dataParsed = new AuthUser();
        dataParsed.role = json.role;
        dataParsed.id = json.id;
        dataParsed.email = json.email;
        dataParsed.fullname = json.fullname;
        dataParsed.subsidiaries = json.subsidiaries;

        return dataParsed;
    }

    /// Devuelve un JSON de la instancia User.
    toJson() {
        return {
            id: this.id,
            fullname: this.fullname,
            email: this.email,
            role: this.role,
            subsidiaries: this.subsidiaries,
        };
    }
}
